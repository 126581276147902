import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import XHR, { BackendOptions } from 'i18next-xhr-backend';

const translations = require('../misc/translations.json');

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    backend: {
      parse: (data: any) => data,
      loadPath: '{{lng}}',
      ajax (locale: string, options: BackendOptions, callback: Function) {
        callback(translations[locale], { status: '200' });
      }
    },
    whitelist: ['nl', 'en'],
    detection: {
      // order and from where user language should be detected
      order: ['path', 'localStorage', 'navigator'],

      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['localStorage'],
      excludeCacheFor: ['cimode', 'cookie'], // languages to not persist (cookie, localStorage)

      // optional htmlTag with lang attribute, the default is:
      htmlTag: typeof document !== 'undefined' ? document.documentElement : null,
    },
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true,
    },
  });

export default i18n;
