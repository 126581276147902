import React, { Component } from 'react';
import { translate, TranslationFunction } from 'react-i18next';

import LanguageSwitcher from '../../components/LanguageSwitcher';
import { i18n } from 'i18next';

interface IProps {
  t?: TranslationFunction,
  i18n?: i18n,
}

class Footer extends Component<IProps> {
  render() {
    const { t } = this.props;
    return (
      <footer id="footer" className="wrapper style1-alt">
        <div className="inner">
          <ul className="menu">
            <li>&copy; Mijn Presta. {t('All rights reserved')}.</li>
            <li>Design: <a href="http://html5up.net" rel="noopener noreferrer">HTML5 UP</a></li>
          </ul>
          <LanguageSwitcher/>
        </div>
      </footer>
    )
  }
}

export default translate()(Footer);
