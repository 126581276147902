import React, { Component } from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import Mailto from 'react-protected-mailto';
import axios from 'axios';
import _ from 'lodash';
import swal from 'sweetalert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { translate, TranslationFunction } from 'react-i18next';
import { i18n } from 'i18next';

interface IProps {
  t: TranslationFunction,
  i18n: i18n,
}

interface IState {
  name: string,
  email: string,
  message: string,
  sent: boolean,
  recaptcha: any,
}

class Contact extends Component<IProps> {
  recaptcha: any;
  state: IState;

  constructor(props: IProps) {
    super(props);
    this.recaptcha = React.createRef();

    let storage = {};
    try {
      storage = JSON.parse(localStorage.getItem('contact'));
    } catch (e) {
    }
    this.state = {
      name: _.get(storage, 'name', ''),
      email: _.get(storage, 'email', ''),
      message: _.get(storage, 'message', ''),
      sent: false,
      recaptcha: null,
    };
  }

  onRecaptchaResolved = () => {
    if (this.state.sent) {
      this.sendMail();
    } else {
      this.recaptcha.reset();
    }
  };

  submitForm = () => {
    this.setState({
      sent: true,
    }, () => this.recaptcha.current.execute());
  };

  saveState = () => {
    if (typeof localStorage !== 'undefined' && typeof localStorage.setItem === 'function') {
      localStorage.setItem('contact', JSON.stringify(_.pick(this.state, ['name', 'email', 'message'])));
    }
  };

  resetState = () => {
    this.setState({
      name: '',
      email: '',
      message: '',
      sent: false,
    }, () => {
      if (typeof localStorage !== 'undefined' && typeof localStorage.setItem === 'function') {
        localStorage.setItem('contact', JSON.stringify({}));
      }
    });
  };

  sendMail = () => {
    const { t } = this.props;

    axios.post(`${process.env.MIJNPRESTA_ENDPOINT}/contact`, {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
      'g-recaptcha-response': this.recaptcha.current.getResponse(),
    })
      .then(({ data }) => {
        if (_.get(data, 'success')) {
          swal({
            icon: 'success',
            title: t('yourMessageHasBeenSent'),
          }).then();
          this.resetState();
        } else {
          swal({
            icon: 'error',
            title: t('unableToSendMessage'),
            text: t('problemWhileSendingRequest'),
          }).then();
        }
      })
      .catch(error => {
        let text = '';
        switch (_.get(error, 'response.data.reason')) {
          case 'name_invalid':
            text = t('nameIsMissing');
            break;
          case 'email_invalid':
            text = t('emailIsInvalid');
            break;
          case 'message_invalid':
            text = t('messageIsInvalid');
            break;
          case 'disposable':
            text = t('doNotUseDisposable');
            break;
          case 'captcha_unverified':
            text = t('unableToVerifyCaptcha');
            break;
          case 'captcha_invalid':
            text = t('recaptchaIsInvalid');
            break;
          default:
            text = t('problemWhileSendingRequest');
            break;
        }
        swal({
          icon: 'error',
          title: t('unableToSendMessage'),
          text,
        });
      })
      .then(() => {
        this.recaptcha.current.reset();
        this.setState({
          sent: false,
        });
      })
    ;
  };

  render() {
    const { t, i18n } = this.props;
    return (
      <section id="contact" className="wrapper style1 fade-up">
        <div className="inner">
          <h2>{t('Get in touch')}</h2>
          <div className="split style1">
            <section>
              <form method="post" action="#">
                <div className="fields">
                  <div className="field half">
                    <label htmlFor="name">{t('Name')}</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={this.state.name}
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                        this.saveState();
                      }}
                    />
                  </div>
                  <div className="field half">
                    <label htmlFor="email">{t('Email')}</label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={this.state.email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                        this.saveState();
                      }}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="message">{t('Message')}</label>
                    <textarea
                      name="message"
                      id="message"
                      rows={5}
                      value={this.state.message}
                      onChange={(e) => {
                        this.setState({ message: e.target.value });
                        this.saveState();
                      }}
                    />
                  </div>
                </div>
                <ul className="actions">
                  <li>
                    <button
                      type="button"
                      className="button submit"
                      onClick={this.submitForm}
                    >
                      {t('Send Message')}
                    </button>
                  </li>
                </ul>
                <Recaptcha
                  locale={i18n.language}
                  sitekey={process.env.RECAPTCHA_SITE_KEY}
                  onResolved={this.onRecaptchaResolved}
                  ref={this.recaptcha}
                />
              </form>
            </section>
            <section>
              <ul className="contact">
                <li>
                  <h3><FontAwesomeIcon icon={faEnvelope} className="contact"/> {t('Email')}</h3>
                  <Mailto email="info@mijnpresta.nl"/>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </section>
    );
  }
}

export default translate()(Contact);
