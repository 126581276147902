import React, { Component } from 'react';
import { Link, withPrefix } from 'gatsby';
import { translate, TranslationFunction } from 'react-i18next';
import LazyLoad from 'react-lazyload';

import { Consumer as HomepageConsumer } from '../../context/homepage';
import { i18n } from 'i18next';

interface IProps {
  t?: TranslationFunction,
  i18n?: i18n,
}

class One extends Component<IProps> {
  render() {
    const { t } = this.props;
    return (
      <HomepageConsumer>
        {remark => <section id="one" className="wrapper style2 spotlights">
          {(remark as Array<any>).map(({ node }: any) => (
              <section key={node.id}>
                <LazyLoad height={200} offset={100}>
                  <Link
                    to={withPrefix(node.frontmatter.path)}
                    className="image"
                    style={{
                      minWidth: '320px',
                      backgroundImage: `url("${node.frontmatter.thumb}")`,
                      backgroundPosition: 'center center',
                    }}
                  />
                </LazyLoad>
                <div className="content">
                  <div className="inner">
                    <Link
                      to={withPrefix(node.frontmatter.path)}
                    >
                      <h2>{node.frontmatter.title}</h2>
                    </Link>
                    <p>{node.excerpt}</p>
                    <ul className="actions">
                      <li>
                        <Link to={withPrefix(node.frontmatter.path)} className="button">{t('Learn more')}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
          ))}
        </section>
        }
      </HomepageConsumer>
    );
  }
}

export default translate()(One);

