import React, { Component } from 'react';
import { Link, withPrefix } from 'gatsby';
import { Link as ScrollLink } from 'react-scroll';
import { translate, TranslationFunction } from 'react-i18next';

import ScrollButton from '../ScrollButton';
import _ from 'lodash';
import { i18n } from 'i18next';

const logoWit = require('../../images/mijnprestalogobalk_wit.svg');

const links = {
  'en': {
    'blog': 'blog'
  },
  'nl': {
    'blog': 'blog'
  }
};

interface IProps {
  t?: TranslationFunction,
  i18n?: i18n,
}

class Welcome extends Component<IProps> {
  render() {
    const { t, i18n } = this.props;
    return (
      <section id="intro" className="wrapper style1 fullscreen fade-up">
        <div className="inner">
          <img src={logoWit} className="intro-logo" alt=""/>
          <ul className="actions">
            <li>
              <Link to={withPrefix(`/${i18n.language}/${_.get(links, `${i18n.language}.blog`)}`)}
                    className="button scrolly"
              >
                {t('Blog')}
              </Link>
            </li>
            <li>
              <ScrollLink
                to="contact"
                spy={false}
                smooth={true}
                offset={20}
                isDynamic={true}
                className="button scrolly"
              >
                {t('Contact Us')}
              </ScrollLink>
            </li>
          </ul>
        </div>
        <div className="welcome-latest-posts">
          <h2 style={{ position: 'absolute', bottom: 0, right: '20px', fontSize: '3.5em', marginBottom: 0 }}>{t('Latest Posts')}</h2>
          <ScrollButton/>
        </div>
      </section>
    );
  }
}

export default translate()(Welcome);

