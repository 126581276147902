import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { TranslationFunction } from 'i18next';

interface IProps {
  t: TranslationFunction,
}

interface IState {
  display: string,
}

class ScrollButton extends Component<IProps> {
  state: IState = {
    display: 'block',
  };

  constructor(props: any) {
    super(props);

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
          this.setState({
            display: 'none',
          });
        }
      })
    }
  }


  render() {
    const { t } = this.props;
    return (
      <span
        className="scroll-btn"
        style={{
          position: 'absolute',
          bottom: '10px',
          marginBottom: 0,
          fontSize: '10pt',
          display: this.state.display
        }}>
        <span>
          <span className="mouse">
            <span>
            </span>
          </span>
        </span>
        <span style={{ display: 'inline' }}>
          <br/>
          {t('scroll me')}
        </span>
      </span>
    );
  }
}

export default translate()(ScrollButton);
