import React from 'react';

import IndexLayout from '../layouts/IndexLayout';
import Sidebar from '../components/Sidebar';
import Welcome from '../components/home/Welcome';
import One from '../components/home/One';
import Contact from '../components/home/Contact';
import Footer from '../components/footer/Footer';

export default class IndexPage extends React.Component {
  render() {
    return (
      <IndexLayout>
        <Sidebar/>
        <div id="wrapper">
          <Welcome/>
          <One/>
          <Contact/>
        </div>
        <Footer/>
      </IndexLayout>
    )
  }
};
