import React, { Component } from 'react';
import { Link } from 'react-scroll';

import { translate, TranslationFunction } from 'react-i18next';

interface IProps {
  t: TranslationFunction,
}

class Sidebar extends Component<IProps> {
  render() {
    const duration = 500;
    const { t } = this.props;
    return (
      <section id="sidebar">
        <div className="inner">
          <nav>
            <ul style={{ cursor: 'pointer' }}>
              <li>
                <Link
                  to="intro"
                  spy={true}
                  smooth={true}
                  duration={duration}
                  offset={-200}
                  isDynamic={true}
                >
                  {t('Welcome')}
                </Link>
              </li>
              <li>
                <Link
                  to="one"
                  spy={true}
                  smooth={true}
                  duration={duration}
                  offset={-200}
                  isDynamic={true}
                >
                  {t('Latest Posts')}
                </Link>
              </li>
              <li>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={duration}
                  offset={-200}
                  isDynamic={true}
                >
                  {t('Get in touch')}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </section>
    );
  }
}

export default translate()(Sidebar);
