import React, { Component, CSSProperties } from 'react';
import { translate } from 'react-i18next';
import { push } from 'gatsby';
import _ from 'lodash';

import { i18n } from 'i18next';

interface IProps {
  i18n: i18n,
}

interface IState {
  language: string,
}

class LanguageSwitcher extends Component<IProps> {
  state = {
    language: _.get(this.props, 'i18n.language', 'en'),
  };

  static getDerivedStateFromProps(nextProps: Partial<IProps>, prevState: Partial<IState>) {
    return {
      ...prevState,
      language: nextProps.i18n.language,
    };
  }

  handleChangeLanguage = (lang: string) : void => {
    if (typeof window === 'undefined') {
      return;
    }

    const { i18n } = this.props;
    const path = window.location.pathname;
    if (path.match(/^\/(nl|en)\//)) {
      const hrefLang: HTMLLinkElement = document.querySelector(`link[hreflang="${lang}"]`);
      if (hrefLang != null) {
        const a = document.createElement('A') as HTMLAnchorElement;
        a.href = hrefLang.href;
        push(a.pathname);
      } else {
        const a = document.createElement('A') as HTMLAnchorElement;
        a.href = window.location.href;
        push(a.pathname.replace(/^\/(nl|en)\//, `/${lang}/`));
      }
    }
    i18n.changeLanguage(lang);
  };

  renderLanguageChoice = ({ code: langCode, label }: any) => {
    let code = _.clone(langCode);
    const style: CSSProperties = {
      width: '45px',
      height: '30px',
      padding: 0,
      marginLeft: '5px',
    };
    if (code === 'en') {
      code = 'us';
      style.width = '57px';
    }
    if (langCode === this.state.language) {
      style.outline = '2px solid white';
    }
    return (
      <img
        src={require(`svg-country-flags/svg/${code}.svg`)}
        onClick={() => this.handleChangeLanguage(langCode)}
        key={code}
        alt={label}
        style={style}
      />
    );
  };

  render() {
    if (typeof window === 'undefined') {
      return null;
    }

    const languages = [
      { code: "en", label: "English" },
      { code: "nl", label: "Dutch" },
    ];

    return (
      <div className="language-select" style={{ width: '114px', float: 'right' }}>
        {languages.map(language => this.renderLanguageChoice(language))}
      </div>
    );
  }
}

export default translate()(LanguageSwitcher);
